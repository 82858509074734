function readyFnc(){
  var btn = document.getElementsByClassName('scroll-top-btn')[0];

  window.addEventListener('scroll', function(){
    if( window.pageYOffset > window.innerHeight/2 ){
      btn.classList.add('active');
    }else{
      btn.classList.remove('active');
    }
  });

  var mmBtn = document.getElementsByClassName('mm-btn')[0];
  if( mmBtn ){
    mContainer = document.getElementsByClassName('mm-container')[0];
    mmBtn.addEventListener('click', function(){
      mmBtn.classList.toggle('open');
      mContainer.classList.toggle('active');
    });
  }

  var btn = document.getElementsByClassName('scroll-top-btn')[0];
  btn.addEventListener('click', function(){
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  });

  var els = document.getElementsByClassName('sm-scroll');
  if( els ){
    for (var i = 0; i < els.length; i++) {
      els[i].addEventListener('click', function(e){
        e.preventDefault();
        var top = 0;
        var href = this.getAttribute('href');
        if( href != '#' ){
          target = document.getElementById(href.replace('#', ''));
          if( target ){
            top = target.offsetTop;
          }
        }
        window.scrollTo({ top: top, left: 0, behavior: 'smooth' });
      });
    }
  }

  var printBtn = document.getElementsByClassName('print-btn')[0];
  if( printBtn ){
    printBtn.addEventListener('click', function(e){
      e.preventDefault();
      var map = window.frames['access-map'];
      if( map ){
        window.open('/print?url=' + encodeURI(map.src), '_blank');
      }
    });
  }

  var postsBtn = document.getElementById('btn-posts');
  if( postsBtn ){
    var postsPage = 1;
    var postsContainer = document.getElementsByClassName('posts-block')[0];
    var loadInd = document.getElementsByClassName('load-ind')[0];
    postsBtn.addEventListener('click', function(e){
      e.preventDefault();
      postsPage++;
      loadInd.classList.add('active')

      var url = '/news_list?page=' + postsPage + '&pageSize=2';
      fetch(url).then(data => data.text())
      .then(data => {
        postsContainer.innerHTML += data;
        loadInd.classList.remove('active')
        if( data.indexOf('all-posts-is-loaded') > 0 ){
          postsBtn.remove();
        }
      }).then(function(){});
    })
  }

  var recruitBtn = document.getElementById('expand-btn-1');
  if( recruitBtn ){
    var group1 = document.getElementById('collapse-group-1');
    recruitBtn.addEventListener('click', function(e){
      group1.classList.toggle('active');
    });
  }

  var recruitBtn2 = document.getElementById('expand-btn-2');
  if( recruitBtn2 ){
    var group2 = document.getElementById('collapse-group-2');
    recruitBtn2.addEventListener('click', function(e){
      group2.classList.toggle('active');
    });
  }

}

if (document.readyState !== 'loading') {
  readyFnc();
} else {
  document.addEventListener('DOMContentLoaded', readyFnc);
}